
import { ApiEndpoint } from "@/types";
import { Vue } from "vue-class-component";
import apiFetch from "@/utils/request";

class CreateApiEndpoint extends Vue {
  apiEndpoint: ApiEndpoint = {
    domain: "",
  };
  error: string = "";
  async save() {
    try {
      await this.createApiEndpoint();
      this.$router.push({ path: "/api-endpoint" });
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
  async createApiEndpoint() {
    const newApiEndpoint: ApiEndpoint = { ...this.apiEndpoint };
    await apiFetch.apiEndpoint.createApiEndpoint(newApiEndpoint);
  }
}
export default CreateApiEndpoint;
