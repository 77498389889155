<template>
  <div class="form">
    <!-- api endpoint form component -->
    <div id="api-endpoint-form" class="form">
      <h2>Creating New API Endpoint</h2>
      <div v-if="error" class="err-msg">{{ error }}</div>
      <div class="form-content">
        <div class="form-input">
          <label for="api-endpoint"> Api Endpoint: </label>
          <input
            id="api-endpoint"
            v-model="apiEndpoint.domain"
            name="api_endpoint"
            type="text"
          />
        </div>
        <button class="save-btn" @click="save">Save</button>
      </div>
    </div>
    <!-- api endpoint form component -->
  </div>
</template>

<script lang="ts">
import { ApiEndpoint } from "@/types";
import { Vue } from "vue-class-component";
import apiFetch from "@/utils/request";

class CreateApiEndpoint extends Vue {
  apiEndpoint: ApiEndpoint = {
    domain: "",
  };
  error: string = "";
  async save() {
    try {
      await this.createApiEndpoint();
      this.$router.push({ path: "/api-endpoint" });
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
  async createApiEndpoint() {
    const newApiEndpoint: ApiEndpoint = { ...this.apiEndpoint };
    await apiFetch.apiEndpoint.createApiEndpoint(newApiEndpoint);
  }
}
export default CreateApiEndpoint;
</script>
